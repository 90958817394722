import React, { Component } from 'react';
import { Link } from 'gatsby';
import { css } from '@emotion/react';
import { FaCertificate } from 'react-icons/fa';
import { StaticImage } from 'gatsby-plugin-image';
import Moment from 'react-moment';
import Carousel from '../components/home/carousel';
import HomePageLayout from '../components/homePageLayout';
import SEO from '../components/seo';
import HeroSection from '../components/home/heroSection';
import useHomeNews from '../hooks/use-home-news';
import useHomeEvents from '../hooks/use-home-events';

const leaderImage = css`
  border-radius: 50%;
  height: auto;
  width: 60%;
  @media only screen and (max-width: 480px) {
    width: 90%;
  }
`;
const leaderName = css`
  margin: 0;
  font-size: 1rem;
  @media only screen and (max-width: 480px) {
    font-size: 1.5rem;
  }
`;

const leaderTitle = css`
  margin: 0;
  font-size: 0.75rem;
  font-style: italic;
  line-height: 1;
  color: #bca577;
  @media only screen and (max-width: 480px) {
    font-size: 1.25rem;
  }
`;

const eventsListItem = css`
  list-style-type: none;
  line-height: 1.5;
`;

const newsListItem = css`
  list-style-type: none;
  line-height: 2.5;
`;

export default function IndexPage() {
  const currentNews = useHomeNews();
  const homeEvents = useHomeEvents();
  const today = new Date();
  const currentEvents = homeEvents.filter((event) => {
    let eventEndDate = new Date(event.endDate);
    if (today < eventEndDate) {
      return event;
    }
    return null;
  });

  return (
    <HomePageLayout>
      <SEO title='Home' />
      <Carousel />
      <HeroSection title='Mission and Vision'>
        <>
          <h3 className='font-heading-lg'>Mission</h3>
          <p className='line-height-serif-6  font-serif-lg text-light'>
          MEDCoE develops medical professionals, integrates medical capabilities, and drives change in Army Medicine to enable the Joint Force to win the Nation&#8242;s wars.
          </p>
          <h3 className='font-heading-lg'>Vision</h3>
          <p className='line-height-serif-6  font-serif-lg text-light'>
            To be the foundation on which Army Medicine is built, sustained and
            transformed.
          </p>
          <Link to='/about-us' className='usa-button'>
            Learn More
          </Link>
        </>
      </HeroSection>
      <HeroSection title='Our Command Team' bgColor='#112f4e' textColor='white'>
        <>
          <div className='grid-row '>
            <div className='tablet:grid-col'>
              <StaticImage
                src='https://medcoeckapwstorprd01.blob.core.usgovcloudapi.net/pfw-images/site/BGMurray.jpg'
                alt='commander'
                css={leaderImage}
              />
              <p css={leaderName}>BG Clinton Murray</p>
              <p css={leaderTitle}>Commanding General</p>
              <a
                href='https://medcoeckapwstorprd01.blob.core.usgovcloudapi.net/pfw-images/dbimages/BGMurray-Bio.pdf'
                css={css`
                  color: white;
                `}
              >
                Read Bio ⟩
              </a>
            </div>
            <div className='tablet:grid-col'>
              <StaticImage
                src='https://medcoeckapwstorprd01.blob.core.usgovcloudapi.net/pfw-images/dbimages/laragione.jpg'
                alt='command sergeant major'
                css={leaderImage}
              />
              <p css={leaderName}>CSM Victor J. Laragione</p>
              <p css={leaderTitle}>Command Sergeant Major</p>
              <a
                href='https://medcoeckapwstorprd01.blob.core.usgovcloudapi.net/pfw-images/dbimages/230920_laragione-bio.pdf'
                css={css`
                  color: white;
                `}
              >
                Read Bio ⟩
              </a>
            </div>
            <div className='tablet:grid-col'>
              <StaticImage
                src='https://medcoeckapwstorprd01.blob.core.usgovcloudapi.net/pfw-images/site/Holland.jpg'
                alt='deputy'
                css={leaderImage}
              />
              <p css={leaderName}>Joseph Holland</p>
              <p css={leaderTitle}>Deputy to the CG</p>
              <a
                href='https://medcoeckapwstorprd01.blob.core.usgovcloudapi.net/pfw-images/site/Mr-Holland-Bio.pdf'
                css={css`
                  color: white;
                `}
              >
                Read Bio ⟩
              </a>
            </div>
          </div>
          <div
            className='grid-row flex-align-center'
            css={css`
              margin-top: 16px;
            `}
          >
            <div className='tablet:grid-col'>
              <StaticImage
                src='https://medcoeckapwstorprd01.blob.core.usgovcloudapi.net/pfw-images/dbimages/ColonelAristotle.jpg'
                alt='chief of staff'
                css={leaderImage}
              />
              <p css={leaderName}>COL Aristotle Vaseliades</p>
              <p css={leaderTitle}>Chief of Staff</p>
              <a
                href='https://medcoeckapwstorprd01.blob.core.usgovcloudapi.net/pfw-images/dbimages/ColonelAristotle.pdf'
                css={css`
                  color: white;
                `}
              >
                Read Bio ⟩
              </a>
            </div>
            <div className='tablet:grid-col'>
              <StaticImage
                src='https://medcoeckapwstorprd01.blob.core.usgovcloudapi.net/pfw-images/dbimages/nelson.jpg'
                alt='command chief warrant officer'
                css={leaderImage}
              />
              <p css={leaderName}>CW5 Lee Nelson</p>
              <p css={leaderTitle}>Command Chief Warrant Officer</p>
               <a
                href='https://medcoeckapwstorprd01.blob.core.usgovcloudapi.net/pfw-images/dbimages/nelson-bio.pdf'
                css={css`
                  color: white;
                `}
              >
                Read Bio ⟩
              </a>
            </div>
            <div className='tablet:grid-col'></div>
          </div>
        </>
      </HeroSection>
      <HeroSection title='Featured Events'>
        <>
          <ul
            css={css`
              padding: 0;
            `}
          >
            {currentEvents.length > 0 ? (
              currentEvents
                .sort((a, b) => {
                  if (a.startDate < b.startDate) {
                    return -1;
                  }
                  return 1;
                })
                .map((event) => (
                  <Link
                    key={event.id}
                    to={`/events/${event.name
                      .toLowerCase()
                      .replace(/ /g, '-')
                      .replace(/[^\w-]+/g, '')}`}
                  >
                    <li css={eventsListItem} className='font-sans-md line-height-sans-5'>
                      <Moment format='MMM DD'>{event.startDate}</Moment>
                      {event.startDate === event.endDate ? (
                        ''
                      ) : (
                        <>
                          {` - `}
                          <Moment format='MMM DD'>{event.endDate}</Moment>
                        </>
                      )}{' '}
                      <span className='text-bold text-italic'>{event.name}</span>
                    </li>
                  </Link>
                ))
            ) : (
              <p>Sorry no events, please check back later.</p>
            )}
          </ul>
          <Link to='/upcoming-events' className='usa-button'>
            All Events
          </Link>
        </>
      </HeroSection>
      <HeroSection title='News' bgColor='#112f4e' textColor='white'>
        <>
          <ul
            css={css`
              padding: 0;
            `}
          >
            {/* added this one manually...COP start */}
            {/* <li
                    css={newsListItem}
                    className='font-sans-md line-height-sans-5 text-white '
                  >
                    <FaCertificate color='#e31c3d' /> <a href='https://medssr.tradoc.army.mil/cac-docs/cop/MEDCoECOPEditionTwo30OCT24.pdf' css={css`
                  color: white; text-decoration: none;
                `} >COP (Command Operating Picture)</a>
                  </li> */}
              {/* added this one manually...COP end */}
            {currentNews.length > 0 ? (
              currentNews.map((newsArticle) => (
                <Link
                  key={newsArticle.id}
                  to={`/news/${newsArticle.name
                    .toLowerCase()
                    .replace(/ /g, '-')
                    .replace(/[^\w-]+/g, '')}`}
                  className='text-no-underline'
                >
                  <li
                    css={newsListItem}
                    className='font-sans-md line-height-sans-5 text-white '
                  >
                    <FaCertificate color='#e31c3d' /> {newsArticle.name}
                  </li>
                </Link>
              ))
            ) : (
              <p> Sorry no news, please check back later.</p>
            )}
          </ul>
          <Link to='/announcements' className='usa-button'>
            All News
          </Link>
        </>
      </HeroSection>
  {/*     <HeroSection title='Council on Occupational Education'>
        <>
          <p className='line-height-serif-6  font-serif-lg text-light'>
          Medical Center of Excellence (MEDCoE) is applying for reaffirmation of accreditation (institutional) with the Commission of the Council on Occupational Education (COE). The COE team will arrive June 6<sup>th</sup> and depart June 9<sup>th</sup>. Persons wishing to make comments should either write to the Executive Director of the Commission, Council on Occupational Education, 7840 Roswell Road, Building 300, Suite 325, Atlanta, Georgia 30350, or submit their comments through the Council&#8242;s website <a href="https://www.council.org">www.council.org (External Link)</a>. MEDCoE looks forward to hosting the COE team. Army Medicine Starts HERE!!
          </p>
        </>
      </HeroSection> */}
           <HeroSection title='COP'>
        <>
        <p className='line-height-serif-6  font-serif-lg text-light' css={css`
                  font-weight: bold;`}>
        Command Operating Picture
          </p>
        <p className='line-height-serif-6  font-serif-lg text-light' css={css`
                  font-weight: bold;`}>
          Mission:
          </p>
          <p className='line-height-serif-6  font-serif-lg text-light'>
          MEDCoE develops medical professionals, integrates medical capabilities, and drives change in Army Medicine to enable the Joint Force to win the Nation&#8242;s wars.
          </p>
          <Link to='https://medssr.tradoc.army.mil/cac-docs/cop/MEDCoECOPEditionTwo30OCT24.pdf' className='usa-button'>
           Learn More
          </Link>
        </>
      </HeroSection> 
    </HomePageLayout>
  );
}

// TODO: Add prop types
